import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery, { breakpoints } from 'utils/media-query';

import styles from './styles.scss';

export const weeksArray = (num) => {
  const range = [];
  for (let i = 1; i <= num; i += 1) {
    range.push(i);
  }
  return range;
};

export const percentageCompletion = (currentProgress, totalWeeks) => {
  const percent = (currentProgress / totalWeeks) * 100;

  return percent > 100 ? 100 : percent;
};

const ProgressBar = ({ currentProgress, totalWeeks, isCompletable }) => (
  <div className={styles.container}>
    <div
      className={`${styles.progress} ${isCompletable ? styles.active : ''}`}
      style={{
        height: `${percentageCompletion(currentProgress, totalWeeks)}%`
      }}
    />
    <ol>
      {weeksArray(totalWeeks).map((week) => (
        <li key={week} className={styles.week}>
          <MediaQuery minWidth={breakpoints.screenXs}>
            <span className="text-callout">{week}</span>
          </MediaQuery>

          <MediaQuery maxWidth={breakpoints.screenXs}>
            <span className="text-footnote">{week}</span>
          </MediaQuery>
        </li>
      ))}
    </ol>
  </div>
);

ProgressBar.propTypes = {
  currentProgress: PropTypes.number.isRequired,
  totalWeeks: PropTypes.number.isRequired,
  isCompletable: PropTypes.bool.isRequired
};

export default ProgressBar;
