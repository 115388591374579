import React from 'react';
import MediaQuery, { breakpoints } from 'utils/media-query';
import getIcon from 'utils/icon-mapper';
import PropTypes from 'prop-types';

import {
  AchievementsTrophyHighlightedSmall,
  AchievementsTrophyNormalXsmall,
  ActivitySegmentNormalSmall,
  ActivitySegmentNormalXsmall,
  NavigationCalendarNormalSmall,
  NavigationCalendarNormalXsmall
} from '@strava/icons';
import styles from './styles.scss';

const renderIcon = (Component, index, fill) => {
  return (
    <div className={styles.icon} key={styles.icon}>
      <Component key={index} fill={fill} />
    </div>
  );
};

/* eslint-disable react/no-array-index-key */
function getIcons(iconNames) {
  const iconComponents = [];
  const xSmallBlankIcon = (
    <div
      className={`${styles.icon}  ${styles.blankXSmall}`}
      key={styles.icon}
    />
  );
  iconNames.forEach((iconName, index) => {
    switch (iconName) {
      case 'achievements_trophy_normal_medium':
        iconComponents.push(
          renderIcon(AchievementsTrophyHighlightedSmall, index, '#fec746')
        );
        break;
      case 'activity_segment_normal_medium':
        iconComponents.push(renderIcon(ActivitySegmentNormalSmall, index));
        break;
      case 'navigation_calendar_normal_medium':
        iconComponents.push(renderIcon(NavigationCalendarNormalSmall, index));
        break;
      case 'achievements_trophy_normal_xsmall':
        iconComponents.push(renderIcon(AchievementsTrophyNormalXsmall, index));
        break;
      case 'activity_segment_normal_xsmall':
        iconComponents.push(renderIcon(ActivitySegmentNormalXsmall, index));
        break;
      case 'navigation_calendar_normal_xsmall':
        iconComponents.push(renderIcon(NavigationCalendarNormalXsmall, index));
        break;
      case 'blank_xsmall':
        iconComponents.push(xSmallBlankIcon);
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const iconComponent = (
          <div className={styles.icon} key={styles.icon}>
            {getIcon(iconName)}
          </div>
        );
        iconComponents.push(iconComponent);
        break;
    }
  });
  return iconComponents;
}

const wrapInAnchor = (children, href) => <a href={href}>{children}</a>;

const textBlock = (title, subtitles, textClass) => (
  <>
    {/* Bigger screens - tablets & desktop */}
    <MediaQuery minWidth={breakpoints.screenXs}>
      <div className={`text-body ${styles.text} ${textClass}`}>{title}</div>
    </MediaQuery>

    {/* Smaller screens - mobile */}
    <MediaQuery maxWidth={breakpoints.screenXs}>
      <div className={`text-subhead ${styles.text}`}>{title}</div>
    </MediaQuery>
    {subtitles &&
      subtitles.map((subtitle) => (
        <div
          key={subtitle.text}
          className={`text-caption1 ${styles.subtitle} ${
            subtitle.href ? styles.truncate : ''
          }`}
        >
          {subtitle.href
            ? wrapInAnchor(subtitle.text, subtitle.href)
            : subtitle.text}
        </div>
      ))}
  </>
);

const TextWithIcon = ({
  title,
  subtitles,
  icons,
  destinationUrl,
  textClass,
  iconSpace
}) => (
  <div className={styles.container}>
    <div>{getIcons(icons)}</div>
    <br className={styles.clearBoth} />
    <div
      className={styles.textFields}
      style={{
        marginLeft: iconSpace
      }}
    >
      {destinationUrl
        ? wrapInAnchor(textBlock(title, subtitles, textClass), destinationUrl)
        : textBlock(title, subtitles, textClass)}
    </div>
  </div>
);

TextWithIcon.defaultProps = {
  subtitles: null,
  destinationUrl: null,
  textClass: '',
  iconSpace: '20px'
};

TextWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string
    })
  ),
  icons: PropTypes.arrayOf(PropTypes.string).isRequired,
  destinationUrl: PropTypes.string,
  textClass: PropTypes.string,
  iconSpace: PropTypes.string
};

export default TextWithIcon;
